import { template as template_b0dfd53084224d87819c7c82807b6070 } from "@ember/template-compiler";
const FKLabel = template_b0dfd53084224d87819c7c82807b6070(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
