import { template as template_317137432977496bbe1767ffc297a219 } from "@ember/template-compiler";
const FKControlMenuContainer = template_317137432977496bbe1767ffc297a219(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
