import { template as template_6d383b8cab7448409177c6f054a7ddc8 } from "@ember/template-compiler";
const FKText = template_6d383b8cab7448409177c6f054a7ddc8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
